// import Swiper JS
import Swiper from 'swiper/bundle';
import UIkit from 'uikit';
// import Swiper styles
// import 'swiper/css/bundle';
var slider = document.getElementsByClassName('vy_team');
if (slider.length > 0) {
const swiper = new Swiper('.swiper', {
  slidesPerView: 2,
  speed: 400,
  spaceBetween: 20,
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 0,
    slideShadows: false,
    depth: 500,
    // modifier:1,
    // scale:0.9,
  },
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  loop: true,
  slideToClickedSlide: true,
  // freeMode: true,
  autoHeight:true,
  breakpoints:{
    960:{
      slidesPerView: 3,
    },
  },

});

swiper.slideNext();

$(document).ready(function () {
  // console.log('ready :D');
  var util = UIkit.util;
  var el = util.$('.swiper-inview');
  UIkit.scrollspy(el, { repeat: false });
  util.on(el, 'inview', function () {
    // console.log('inview');
    swiper.slideTo(15, 2000, false);
    // for (let i = 0; i < 15; i++) {
    //   swiper.slideTo(1, 100, false);
    // }
  });

});
}
