/* eslint-disable */
import UIkit from 'uikit';

$(function(){
  // FILTERS
  $('.vy_filters_blogs_select').change(function(){
    ajax_filter_blog(1);
  });
  $('.vy_filters_search_form').submit(function(e){
    ajax_filter_blog(1);
    // $('.vy_filters_search_input').blur();
    e.preventDefault();
  });
  // $('.vy_filters_search_input').blur(function(e){
  //     console.log('search unfocus');
  //     ajax_filter_blogs(1);
  // });
  $('.vy_filters_search_button').click(function(e){
    ajax_filter_blog(1);
  });

  $('.vy_filters_blogs_dropdown a').click(function(e){
    $('.vy_filters_blogs_dropdown a').removeClass('uk-active');
    $(this).addClass('uk-active');
    // $('.vy_filters_blogs_select')
    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);
    $('.vy_filters_blogs_select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true).trigger('change');
    if ($(this).parents('.uk-dropdown.vy_filters_dropdown').length){
      UIkit.dropdown( $(this).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }
    // $('.vy_filters_reset').addClass('uk-active');
    e.preventDefault();
  });

  $('.vy_filters_recipes_select').change(function(){
    ajax_filter_recipes(1);
  });
  $('.vy_filters_recipes_search_form').submit(function(e){
    ajax_filter_recipes(1);
    e.preventDefault();
  });
  $('.vy_filters_recipes_search_button').click(function(e){
    ajax_filter_recipes(1);
  });

  $('.vy_filters_recipes_dropdown a').click(function(e){
    $('.vy_filters_recipes_dropdown a').removeClass('uk-active');
    $(this).addClass('uk-active');
    // $('.vy_filters_recipes_select')
    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);
    $('.vy_filters_recipes_select[name="'+id+'"] option[value="'+slug+'"]').prop('selected', true).trigger('change');
    if ($(this).parents('.uk-dropdown.vy_filters_dropdown').length){
      UIkit.dropdown( $(this).parents('.uk-dropdown.vy_filters_dropdown')).hide(false);
    }
    e.preventDefault();
  });

  //PAGINATION
  $(document).on( "click",'.vy_pager_blog a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');

    ajax_filter_blog(res[1]);

    e.preventDefault();
  });

  $(document).on( "click",'.vy_pager_recipes a.page-numbers', function(e) {
    $(this).addClass('uk-active');
    var url = $(this).attr('href');
    var res = url.split("page/");
    if(res.length < 2) res = url.split('paged=');

    ajax_filter_recipes(res[1]);

    e.preventDefault();
  });

});

function ajax_filter_blog($paged){
  var selected_filter = {};
  var search = '';
  var paged = $paged;

  $('.filters__used').empty();
  search = $('.vy_filters_search_input').val();
  if($('select[name="vy_filters_blog_categories"]').val() != '') selected_filter['category'] = $('select[name="vy_filters_blog_categories"]').val();

  $('.vy_blog_listing').addClass('vy_ajax_loading');

  // console.log(search);

  var data = {
    action: 'filter_blogs', // function to execute
    afp_nonce: afp_vars.afp_nonce, // wp_nonce
    filters: selected_filter, // selected tag
    paged: paged, // selected tag
    // post_id: $('.c-block-_units').attr('data-url'),
    // icl_current_language: $('#icl_current_language').val(),
    search: search, // selected tag
  };


  $.post( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {

      $('.vy_blog_listing_wrapper').empty();
      $('.vy_blog_listing_wrapper').append( response );
      $('.vy_blog_listing').removeClass('vy_ajax_loading');

    };
  });

}

function ajax_filter_recipes($paged){
  var selected_filter = {};
  var search = '';
  var paged = $paged;

  $('.filters__used').empty();
  search = $('.vy_filters_recipes_search_input').val();
  if($('select[name="vy_filters_recipes_categories"]').val() != '') selected_filter['category'] = $('select[name="vy_filters_recipes_categories"]').val();

  $('.vy_archive_listing').addClass('vy_ajax_loading');

  // console.log(search);

  var data = {
    action: 'filter_recipes', // function to execute
    afp_nonce: afp_vars.afp_nonce, // wp_nonce
    filters: selected_filter, // selected tag
    paged: paged, // selected tag
    // post_id: $('.c-block-_units').attr('data-url'),
    // icl_current_language: $('#icl_current_language').val(),
    search: search, // selected tag
  };


  $.post( afp_vars.afp_ajax_url, data, function(response) {
    if( response ) {

      $('.vy_archive_listing_wrapper').empty();
      $('.vy_archive_listing_wrapper').append( response );
      $('.vy_archive_listing').removeClass('vy_ajax_loading');

    };
  });
}

function ajax_filter(){


    var data = {
        action: 'filter_directory', // function to execute
        afp_nonce: afp_vars.afp_nonce, // wp_nonce
        // filters: selected_filter, // selected tag
        // paged: paged, // selected tag
        // post_id: $('.c-block-_units').attr('data-url'),
        // icl_current_language: $('#icl_current_language').val(),
        // search: search, // selected tag
    };


    $.post( afp_vars.afp_ajax_url, data, function(response) {
        if( response ) {



        };
    });

}

