/* eslint-disable */

// import external dependencies
import 'jquery';
// import 'pixi.js/dist/browser/pixi';

import './test';


// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import swiper from './routes/swiper';
import home from './routes/home';
import aboutUs from './routes/about';
import map from './routes/map';
import smoke from './routes/smoke';
import animation from './routes/animation';

import UIkit from 'uikit';
// import Icons from 'uikit/dist/js/uikit-icons';
import Icons from './icons/uikit-icons-bouffe.min.js';

// import smoke from './util/smoke';

UIkit.use(Icons);

import MenuItem from './test';


/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  swiper,
  smoke,
  animation,
  map,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // smoke,
});
function toggleBurger(action){
  var class_active = 'is-active';
  if(action == 'hide'){
    $(".vy_mobile_menu").removeClass(class_active);
  }else if(action == 'show'){
    $(".vy_mobile_menu").addClass(class_active);
  }else{
    $(".vy_mobile_menu").toggleClass(class_active);
  }
}
function toggleClassMenu(action){
  var class_active_html = 'menu-mobile-active';
  if(action == 'hide'){
    $("html").removeClass(class_active_html);
  }else if(action == 'show'){
    $("html").addClass(class_active_html);
  }else{
    $("html").toggleClass(class_active_html);
  }
}
$(function () {
  UIkit.util.on('#offcanvas_mobile', 'show', function () {
    toggleBurger('show');
    toggleClassMenu('show');
  });
  UIkit.util.on('#offcanvas_mobile', 'hide', function () {
    toggleBurger('hide');
  });
  UIkit.util.on('#dropdown_mobile', 'show', function () {
    toggleBurger('show');
    toggleClassMenu('show');
  });
  UIkit.util.on('#dropdown_mobile', 'hide', function () {
    toggleBurger('hide');
  });
  UIkit.util.on('#dropdown_mobile', 'hidden', function () {
    toggleClassMenu('hide');
  });
  UIkit.util.on('#offcanvas_mobile', 'hidden', function () {
    toggleClassMenu('hide');
  });
});
// Load Events
jQuery(document).ready(() => routes.loadEvents());

