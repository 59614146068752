/* eslint-disable */
// File: fade.js
// Import Highway
import Highway from '@dogstudio/highway';

import anime from 'animejs';


// Fade
class Fade extends Highway.Transition {
  in({ from, to, done }) {
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    // UIkit.offcanvas('#offcanvas_mobile').hide();
    $('.uk-offcanvas.uk-open').remove();
    from.remove();

    // Animation
    anime({
      targets: to,
      // delay: 200,
      opacity: [0,1],
      // backgroundColor: '#FFF',
      // borderRadius: ['0%', '50%'],
      easing: 'linear',
      complete: done
    });
   
  }

  out({ from, done }) {
    // Animation
    anime({
      targets: from,
      // delay: 100,
      opacity: [1,0],
      // backgroundColor: '#FFF',
      // borderRadius: ['0%', '50%'],
      easing: 'linear',
      complete: done
    });
    
  }
}

export default Fade;