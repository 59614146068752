/* eslint-disable */
import anime from 'animejs';

if($('#hero-lightpass').length){
    const html = document.documentElement;
    const canvas = document.getElementById("hero-lightpass");
    const context = canvas.getContext("2d");

    const frameCount = 120;
    const currentFrame = index => (
      `${window.location.origin}/app/themes/wp-voyou-bouffe/dist/images/femme_barbe/Femme barbe_1${index.toString().padStart(3, '0')}.jpg`
    )

    const images = [null] // since everything else is 1-indexed, explicitly fill images[0]

    const preloadImages = () => {
      for (let i = 1; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
      }
    };

    const img = new Image()
    img.src = currentFrame(1);
    canvas.width=1920;
    canvas.height=1080;
    img.onload=function(){
      context.drawImage(img, 0, 0);
    }

    const updateImage = index => {
      img.src = currentFrame(index);
      context.drawImage(img, 0, 0);
    }

    window.addEventListener('scroll', () => {  
      const scrollTop = html.scrollTop;
      const maxScrollTop = html.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / maxScrollTop;
      const frameIndex = Math.min(
        frameCount - 1,
        Math.ceil(scrollFraction * frameCount)
      );

      console.log(currentFrame);
      console.log(window.location.origin);
      console.log(img);
      console.log(img.src);
      console.log(frameIndex);
      if(frameIndex == '100'){
        //   alert('100');
      }

      requestAnimationFrame(() => updateImage(frameIndex )) //+ 1
    });

    preloadImages()
}
  ; 