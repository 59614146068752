/* eslint-disable */


// Import Highway
import Highway from '@dogstudio/highway';

 // Import Transitions
import Fade from '../util/transitions/fade';


// Import Renderers
import DefaultRenderer from '../util/renderer/default-renderer';
import UIkit from 'uikit';
// import JXSlider from 'juxtaposejs';
import Map from '../routes/map';

import '../routes/ajax';


$('.uk-slider-nav a').attr('target','_self');

export default {
  init() {

    // to top
    /**
   * inViewport jQuery plugin by Roko C.B.
   * http://stackoverflow.com/a/26831113/383904
   * Returns a callback function with an argument holding
   * the current amount of px an element is visible in viewport
   * (The min returned value is 0 (element outside of viewport)
   */

    $('.uk-button .vy_button_text').each(function(){
      $(this).attr('data-text', $(this).text());
      console.log($(this).attr('data-text', $(this).text()));
    });

    $('.vy_forfait_single').each(function(){
      var value = $(this).attr('data-package');
      if($(this).find('.vy_forfait_single_content > a').on('click', function(){
        $('#frm_form_5_container .uk-select').val(value).change();
        console.log(value);
      }));
    });

    // $(function(){
    //   var lastScrollTop = 0, delta = 5;
    //   $(window).scroll(function(){
    //     var nowScrollTop = $(this).scrollTop();
    //     if(Math.abs(lastScrollTop - nowScrollTop) >= delta){
    //       if (nowScrollTop > lastScrollTop){
    //         // console.log('SCROLLING DOWN ');
    //         $('.vy_navbar').addClass('state-godown');
    //         $('.vy_navbar').removeClass('state-goup');
    //       } else {
    //         // console.log('SCROLLING UP ');
    //         $('.vy_navbar').removeClass('state-godown');
    //         $('.vy_navbar').addClass('state-goup');
    //       }
    //     lastScrollTop = nowScrollTop;
    //     }
    //   });
    // });
  $(function($, win) {
    $.fn.inViewport = function(cb) {
      return this.each(function(i,el) {
        function visPx(){
          var elH = $(el).outerHeight(),
              H = $(win).height(),
              r = el.getBoundingClientRect(), t=r.top, b=r.bottom;
          return cb.call(el, Math.max(0, t>0? Math.min(elH, H-t) : Math.min(b, H)));
        }
        visPx();
        $(win).on("resize scroll", visPx);
      });
    };



  }(jQuery, window));
    // function stickyToTop() {
    //   var doc = document.documentElement;
    //   var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    //   // var stickyEl = document.getElementById('vy_footer_totop');

    //   if (top > 450) {
    //     $('.vy_footer_totop').addClass('uk-active');
    //     $('.vy_navbar').addClass('state-readytostick');
    //   } else {
    //     $('.vy_footer_totop').removeClass('uk-active');
    //     $('.vy_navbar').removeClass('state-readytostick');
    //   }

    // }

    $(".vy_footer_secondary").inViewport(function(px) {
      // console.log( px );
      if(px > 0) {
        $('.vy_footer_totop').css("margin-bottom", px);
      }else{
        $('.vy_footer_totop').css("margin-bottom", '');
      }
    });

    // document.addEventListener('DOMContentLoaded', function() {
    //   stickyToTop();
    // });

    // window.addEventListener('scroll', function() {
    //   stickyToTop();
    // });

    // Image avant/après
    // main function for emulating IE11's "change" event:

    // console.log('change test 3');

    // function onRangeChange(rangeInputElmt, listener) {
    //   console.log('onRangeChange');

    //   var inputEvtHasNeverFired = true;

    //   var rangeValue = {current: undefined, mostRecent: undefined};

    //   rangeInputElmt.addEventListener("input", function(evt) {
    //     inputEvtHasNeverFired = false;
    //     rangeValue.current = evt.target.value;
    //     if (rangeValue.current !== rangeValue.mostRecent) {
    //       listener(evt);
    //     }
    //     rangeValue.mostRecent = rangeValue.current;
    //     console.log('input');
    //     $(rangeInputElmt).parent('.vy_juxtapose_wrapper').find('.is-after').css('width', `${rangeValue.current}%`)
    //     $(rangeInputElmt).parent('.vy_juxtapose_wrapper').find('.vy_juxtapose_tools').css('left', `${rangeValue.current}%`)
    //   });

    //   rangeInputElmt.addEventListener("change", function(evt) {
    //     if (inputEvtHasNeverFired) {
    //       listener(evt);
    //     }
    //     console.log('change');
    //     $(rangeInputElmt).parent('.vy_juxtapose_wrapper').find('.is-after').css('width', `${rangeValue.current}%`)
    //     $(rangeInputElmt).parent('.vy_juxtapose_wrapper').find('.vy_juxtapose_tools').css('left', `${rangeValue.current}%`)
    //   });

    // }

    // // example usage:

    // var myRangeInputElmt = document.querySelector(".vy_juxtapose_wrapper input");

    // var myNumEvts = {input: 0, change: 0, custom: 0};


    // ["input", "change"].forEach(function(myEvtType) {
    //   myRangeInputElmt.addEventListener(myEvtType,  function() {
    //     myNumEvts[myEvtType] += 1;
    //   });
    // });

    // var myListener = function(myEvt) {
    //   myNumEvts["custom"] += 1;
    //   // myRangeValPar.innerHTML = "range value: " + myEvt.target.value;
    //   console.log("range value: " + myEvt.target.value);
    // };

    // onRangeChange(myRangeInputElmt, myListener);


    $('.vy_juxtapose_wrapper input').each(function(){
      $(this).on("input change", (e)=>{
        const sliderPos = e.target.value;
        // console.log(sliderPos);
        $(this).parent('.vy_juxtapose_wrapper').find('.is-after').css('width', `${sliderPos}%`)
        $(this).parent('.vy_juxtapose_wrapper').find('.vy_juxtapose_tools').css('left', `${sliderPos}%`)
      });
    });

    // Map
    Map.init();
    if ( ! String.prototype.getDecimals ) {
      String.prototype.getDecimals = function() {
        var num = this,
          match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if ( ! match ) {
          return 0;
        }
        return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
      }
    }
    $(window).scroll(function() {

      // $('.vy_star-left').css({ transform: 'rotate('+window.pageYOffset+'deg)' });
      // $('.vy_star-right').css({ transform: 'rotate(-'+window.pageYOffset+'deg)' });

      // selectors
      var $window = $(window),
        $body = $('html'),
        $panel = $('.js_change_color');

      // Change 33% earlier than scroll position so colour is there when you arrive.
      var scroll = $window.scrollTop() + ($window.height() / 2);

      $panel.each(function () {
        var $this = $(this);

        // if position is within range of this panel.
        // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
        // Remember we set the scroll to 33% earlier in scroll var.
        if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {

          // Remove all classes on body with color-
          $body.removeClass(function (index, css) {
            return (css.match (/(^|\s)uk-background-\S+/g) || []).join(' ');
          });
          $body.removeClass(function (index, css) {
            return (css.match (/(^|\s)is-scroll-\S+/g) || []).join(' ');
          });

          // Add class of currently active div
          $body.addClass($(this).data('color'));
        }else{
          // Remove all classes on body with color-
          $body.removeClass(function (index, css) {
            return (css.match (/(^|\s)uk-background-\S+/g) || []).join(' ');
          });
          $body.removeClass(function (index, css) {
            return (css.match (/(^|\s)is-scroll-\S+/g) || []).join(' ');
          });
        }
      });

    }).scroll();
    // Quantity "plus" and "minus" buttons
    $( document.body ).on( 'click', '.plus, .minus', function() {

      var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
        currentVal  = parseFloat( $qty.val() ),
        max         = parseFloat( $qty.attr( 'max' ) ),
        min         = parseFloat( $qty.attr( 'min' ) ),
        step        = $qty.attr( 'step' );
      // Format values
      if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
      if ( max === '' || max === 'NaN' ) max = '';
      if ( min === '' || min === 'NaN' ) min = 0;
      if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

      // Change the value
      if ( $( this ).is( '.plus' ) ) {
        if ( max && ( currentVal >= max ) ) {
          $qty.val( max );
        } else {
          $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      } else {
        if ( min && ( currentVal <= min ) ) {
          $qty.val( min );
        } else if ( currentVal > 0 ) {
          $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      }

      // Trigger change event
      $qty.trigger( 'change' );
    });

    $(document).on('mouseenter', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").addClass('uk-active');
      $(this).parent().addClass('uk-transition-active uk-active');
    }).on('mouseleave', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").removeClass('uk-active');
      $(this).parent().removeClass('uk-transition-active uk-active');
    }).on('focus', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").addClass('uk-active');
      $(this).parent().addClass('uk-transition-active uk-active');
    }).on('blur', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").removeClass('uk-active');
      $(this).parent().removeClass('uk-transition-active uk-active');
    });
    $( ".uk-offcanvas-bar a" ).unbind('click').on( "click",function(){
      //.uk-offcanvas-bar
      UIkit.offcanvas('.uk-offcanvas.uk-open').hide();
    });

    $(function() {

// console.log($(".player").data('audio-file'));
$(document).ready(function () {
  // ANIMATION NUMBER RANDOM
    $.fn.extend({
      numAnim: function(options) {
        if ( ! this.length)
          return false;

        this.defaults = {
          endAt: 2560,
          numClass: 'autogen-num',
          duration: 5,   // seconds
          interval: 90  // ms
        };
        var settings = $.extend({}, this.defaults, options);

        var $num = $('<span/>', {
          'class': settings.numClass
        });

        return this.each(function() {
          var $this = $(this);

          // Wrap each number in a tag.
          var frag = document.createDocumentFragment(),
            numLen = settings.endAt.toString().length;
          var x = 0;
          for (x = 0; x < numLen; x++) {
            var rand_num = Math.floor( Math.random() * 10 );
            frag.appendChild( $num.clone().text(rand_num)[0] )
          }
          $this.empty().append(frag);

          var get_next_num = function(num) {
            ++num;
            if (num > 9) return 0;
            return num;
          };

          // Iterate each number.
          $this.find('.' + settings.numClass).each(function() {
            var $num = $(this),
              num = parseInt( $num.text() );

            var interval = setInterval( function() {
              num = get_next_num(num);
              $num.text(num);
            }, settings.interval);

            setTimeout( function() {
              clearInterval(interval);
            }, settings.duration * 1000 - settings.interval);
          });

          setTimeout( function() {
            $this.text( settings.endAt.toString() );
          }, settings.duration * 1000);
        });
      }
    });



  $(".js_numanim").each(function(){
    var util = UIkit.util;
    var el = util.$(this);
    UIkit.scrollspy(el, { repeat: false });
    util.on(el,'inview', function(){
      $(this).numAnim({
        endAt: $(this).text(),
        duration: 2
      })
    });
  });

  // VIDEO ON SCROLL
  if($('.js_videoscroll').length){
    $('.js_videoscroll').each(function(){
      const html = document.documentElement; //$(this).parent('div')[0]
      const canvas = $(this)[0];
      const context = canvas.getContext("2d");

      const frameCount = $(this).data('framecount');
      const folder = $(this).data('folder');
      const currentFrame = index => (
        `${window.location.origin}/app/themes/wp-voyou-bouffe/dist/images/`+folder+`/`+folder+`_1${index.toString().padStart(3, '0')}.jpg`
      )

      const images = [null] // since everything else is 1-indexed, explicitly fill images[0]

      const preloadImages = () => {
        for (let i = 1; i < frameCount; i++) {
          const img = new Image();
          img.src = currentFrame(i);
        }
      };

      const img = new Image()
      img.src = currentFrame(1);
      canvas.width=1920;
      canvas.height=1080;
      img.onload=function(){
        context.drawImage(img, 0, 0);
      }

      const updateImage = index => {
        img.src = currentFrame(index);
        context.drawImage(img, 0, 0);
      }

      var topParent = $(this).parents('.vy_service_videoscroll').offset().top - window.innerHeight;
      var heightParent = $(this).parents('.vy_service_videoscroll').height();
      // console.log('topParent:'+topParent);
      //   console.log('heightParent:'+heightParent);


      window.addEventListener('scroll', () => {
        const scrollTop = html.scrollTop - topParent;
        // const maxScrollTop = html.scrollHeight - window.innerHeight;
        const maxScrollTop =  heightParent;
        const scrollFraction = scrollTop / maxScrollTop;
        const frameIndex = Math.min(
          frameCount - 1,
          Math.ceil(scrollFraction * frameCount)
        );
        if(frameIndex >= 0) requestAnimationFrame(() => updateImage(frameIndex )) //+ 1

        // console.log(topParent);
        // console.log('scrollTop:'+scrollTop);
        // console.log('maxScrollTop:'+maxScrollTop);
        // console.log('scrollFraction:'+scrollFraction);
        // console.log('frameCount:'+frameCount);
        // console.log('frameIndex:'+frameIndex);
        // console.log(window.location.origin);
        // console.log(img);
        // console.log(img.src);
        // if(frameIndex == '100'){
          //   alert('100');
        // }


      });

      preloadImages()
    });
  };


  // SONG PLAYER
  var playing = false,
    // artistname = $(".artist-name"),
    // musicName = $(".music-name"),
    // time = $(".time"),
    fillBar = $(".fillBar");

  let audioData = [
    {
      src:$(".player").data('audio-file')
    }
  ];;

  var song = new Audio();
  var CurrentSong = 0;
  window.onload = load();

  function load() {
    // artistname.html(audioData[CurrentSong].artist);
    // musicName.html(audioData[CurrentSong].name);
    song.src = audioData[CurrentSong].src;
  }

  function playSong() {
    var curSong = audioData[CurrentSong];
    // artistname.html(curSong.artist);
    // musicName.html(curSong.name);
    song.src = curSong.src;
    song.play();
    $(".js_play").addClass("fa-pause");
    $(".js_play").removeClass("fa-play");
    $(".player-track").addClass("active");
  }

  song.addEventListener("timeupdate", function () {
    var position = (100 / song.duration) * song.currentTime;
    var current = song.currentTime;
    var duration = song.duration;
    var durationMinute = Math.floor(duration / 60);
    var durationSecond = Math.floor(duration - durationMinute * 60);
    var durationLabel = durationMinute + ":" + durationSecond;
    var currentSecond = Math.floor(current);
    var currentMinute = Math.floor(currentSecond / 60);
    currentSecond = currentSecond - currentMinute * 60;
    // currentSecond = (String(currentSecond).lenght > 1) ? currentSecond : ( String("0") + currentSecond );
    if (currentSecond < 10) {
      currentSecond = "0" + currentSecond;
    }
    var currentLabel = currentMinute + ":" + currentSecond;
    var indicatorLabel = currentLabel + " / " + durationLabel;

    fillBar.css("width", position + "%");

    $(".time").html(indicatorLabel);
  });

  $(".js_play").click(function playOrPause() {
    if (song.paused) {
      song.play();
      playing = true;
      $(".js_play").addClass("fa-pause");
      $(".js_play").removeClass("fa-play");
      $("img").addClass("active");
    } else {
      song.pause();
      playing = false;
      $(".js_play").removeClass("fa-pause");
      $(".js_play").addClass("fa-play");
      $("img").removeClass("active");
    }
  });

  $("#prev").click(function prev() {
    CurrentSong--;
    if (CurrentSong < 0) {
      CurrentSong = 2;
    }
    playSong();
  });

  $("#next").click(function next() {
    CurrentSong++;
    if (CurrentSong > 2) {
      CurrentSong = 0;
    }
    playSong();
  });
});

      // $(".vy_mobile_menu").click(function() {
      //   $("html, body").animate({ scrollTop: 0 }, "slow");
      // });
      if ( $( "#vy_header_global_alert" ).length ) {
        var globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
        var global_alert = $('#vy_header_global_alert');

        console.log(globalAlertSeen);
        console.log(global_alert);


        if(globalAlertSeen != true){
          console.log('in');
          global_alert.removeClass('uk-hidden');
        }else{

          UIkit.alert(global_alert).close();
        }
        $('.vy_header_global_alert_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalAlertSeen", dropselvalue);
            globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
          }
        });
      }
      if ( $( "#vy_popup" ).length ) {
        var globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
        var global_popup = $('#vy_popup');

        if(globalPopupSeen != true){
          UIkit.modal(global_popup).show();
        }
        $('.vy_popup_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalPopupSeen", dropselvalue);
            globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
          }
        });
      }


      UIkit.util.on('.vy_navbar_ecom_search_drop', 'show', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });
      UIkit.util.on('.vy_navbar_ecom_search_drop', 'hide', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });

      if($('.vy_banner_slideshow').length){
        UIkit.util.on('.vy_banner_slideshow > .uk-slideshow', 'itemshow', function (e) {
          if($(e.target).find('.vy_banner_slideshow_content_wrapper').hasClass('uk-light')){
            $('.vy_banner_slideshow_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_slideshow_dotnav').removeClass('uk-light');
          }
        });
      }
      if($('.vy_banner_hero_item').length){
        UIkit.util.on('.vy_banner_hero > .uk-slider', 'itemshow', function (e) {
          if($(e.target).hasClass('uk-light')){
            $('.vy_banner_hero_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_hero_dotnav').removeClass('uk-light');
          }
        });
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  },
};

// Get IE or Edge browser version
var version = detectIE();
var using_IE = false;

if (version === false) {
  console.log('IE/Edge');
} else if (version >= 12) {
  console.log('Edge ' + version);
} else {
  console.log('IE ' + version);
  using_IE = true;

  setTimeout(function(){
    $('.home .vy_loading').fadeOut();
  }, 4000);
}
// add details to debug result
console.log (window.navigator.userAgent);

// Call Highway.Core once.
if($('.transition').length != 0  && using_IE == false){
  const H = new Highway.Core({
    transitions: {
      default: Fade
    },
    renderers: {
      name: DefaultRenderer,
      //home: TimeOut
    }
  });
  H.on('NAVIGATE_END', ({ location }) => {
    // Check Anchor
    if (location.anchor) {
      // Get element
      const el = document.querySelector(location.anchor);

      if (el) {
        // Scroll to element
        window.scrollTo(el.offsetLeft, el.offsetTop);

      }
    }
  });
}


function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
