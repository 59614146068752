/* eslint-disable */

import { ready } from 'jquery';
import lottieWeb from 'lottie-web';
import UIkit from 'uikit';

var animation = new Array();

function loadBMAnimation(element,i) {
  var animation_params = {
    name: 'animation '+i,
    container: element,
    renderer: "svg",
    loop: Boolean($(element).data('loop')),
    autoplay: Boolean($(element).data('autoplay')),
    path: $(element).data('path')
  }
  if ($(element).attr('data-preserveaspectratio')) { animation_params['rendererSettings'] = { preserveAspectRatio:$(element).data('preserveaspectratio') } }
  animation[i] = lottieWeb.loadAnimation(animation_params);

  // console.log(animation_params);

  animation[i].addEventListener('DOMLoaded', function() {
    console.log('load');
    $(element).addClass('is-loaded');
  });

  if ($(element).attr('data-onhover') && Boolean($(element).data('onhover')) == true) {
    var directionMenu = 1;
    $(element).parents('.vy_link_cover_wrapper').on({
      mouseenter: function () {
        animation[i].setDirection(directionMenu);
        animation[i].loop = true;
        animation[i].play();
      },
      mouseleave: function () {
        animation[i].setDirection(-directionMenu);
        animation[i].loop = false;
        animation[i].play();
      }
    });
  }else{
    var util = UIkit.util;
    var el = util.$(element);
    UIkit.scrollspy(el, { repeat: true });
    util.on(el,'inview', function(){
      animation[i].play();
    });
  }


  if(Boolean($(element).data('repeat'))){
    util.on(el,'outview', function(){
      animation[i].stop();
    });
  }
}

$(function() {
  var element = document.getElementsByClassName("js_animation");

  for (var i = 0; i < element.length; i++) {
    loadBMAnimation(element[i],i);
  }
});
